import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../config/firebase'

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
  })
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrorMessage(null)

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address')
      return
    }

    const sqlRegex = /([';])/g
    if (sqlRegex.test(formData.email) || sqlRegex.test(formData.password)) {
      setErrorMessage('Invalid credentials. Please try again.')
      return
    }

    if (!formData.email || !formData.password) {
      setErrorMessage("Email and password can't be empty")
      return
    }

    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        if (user) {
          toast.success(`Login successful`, {
            theme: 'colored',
          })
          navigate('/events/event-list')
        }
      })
      .catch((error) => {
        const errorCode = error.code
        console.log('error', error)
        setErrorMessage(`Invalid credentials. Please try again.`)
      })
  }

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <p>{errorMessage}</p>}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">Remember me</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs-10 mb-0" to={`/authentication/${layout}/forgot-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button type="submit" color="primary" className="mt-3 w-100" disabled={!formData.email || !formData.password}>
          Log in
        </Button>
      </Form.Group>
    </Form>
  )
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
}

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false,
}

export default LoginForm
