import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CrmStats from './CrmStats';
import LocationBySession from './LocationBySession/LocationBySession';
import RecentLeads from './recent-leads/RecentLeads';
import Greetings from './greetings/Greetings';
import VerifiedUsers from './ActiveUsers';
import UnauthorizedUsers from './UnauthorizedUsers';
import { users } from 'data/dashboard/default'
import { notifications } from 'data/dashboard/ecom';

const Crm = () => {
  return (
    <>
      <Greetings notifications={notifications} />
      <br />
      <Row className="g-3">
          <RecentLeads />

      </Row>
      <br />
      <Row className="g-3">
          <VerifiedUsers users={users} />
      </Row>
      <br />
      <Row className="g-3">
          <UnauthorizedUsers users={users} />
      </Row>
    </>
  );
};

export default Crm;
