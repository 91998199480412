import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const StockListHeader = ({ title }) => {
  return (
    <Row className="flex-between-center flex-column flex-sm-row">
      <Col sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
      </Col>
      <Col sm="auto" className=""></Col>
    </Row>
  )
}

StockListHeader.propTypes = {
  title: PropTypes.string,
}

export default StockListHeader
