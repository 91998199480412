import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import Avatar from 'components/common/Avatar'
import CardDropdown from 'components/common/CardDropdown'
import FalconCardFooterLink from 'components/common/FalconCardFooterLink'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1 sticky-column text-900' },
    cellProps: {
      className: 'fw-semibold'
    },
    Cell: ({ row }) => {
      const { name, surname, id, type } = row.original;
      return (
        <Link 
          to={`/e-commerce/customer-details/${id}`}
          state={{ userType: 'unqualified' }} 
        >
          <Flex alignItems="center">
            <h6 className="mb-0">{`${name} ${surname}`}</h6>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: { className: 'text-900' },
    Cell: ({ row }) => (
      <a href={`mailto:${row.original.email}`} className="mb-0">
        {row.original.email}
      </a>
    ),
  },
  {
    accessor: 'city',
    Header: 'City',
    headerProps: { className: 'text-800' },
    cellProps: { className: 'py-3' },
    Cell: ({ row }) => (
      <Flex alignItems="center">
        <p className="mb-0 ps-3 country text-700">{row.original.city}</p>
      </Flex>
    ),
  },
  {
    accessor: 'country',
    Header: 'Country',
    headerProps: { className: 'text-800' },
    cellProps: { className: 'py-3' },
    Cell: ({ row }) => (
      <Link to="#!">
        <Flex alignItems="center">
          <p className="mb-0 ps-3 country text-700">{row.original.country}</p>
        </Flex>
      </Link>
    ),
  },
]

const RecentLeads = () => {
  const [unqualified, setUnqualified] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnqualified = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': 'p5ovMzoPPn7cDuZ94oeDa4IKJnry6vtJ93VL9lP2',
        }

        const response = await fetch('https://ozz84ht568.execute-api.us-east-1.amazonaws.com/prod/users?type=unqualified', {
          method: 'GET',
          headers,
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json();
        setUnqualified(data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data: ' + error.message)
        setLoading(false)
      }
    }

    fetchUnqualified();
  }, []);

  if (loading) return <div>Loading...</div>
  if (error) return <div>{error}</div>

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={unqualified}
      selection
      selectionColumnWidth={28}
      selectionHeaderClassname="py-0"
      sortable
      pagination={false}
    >
      <Card>
        <FalconCardHeader
          title="Unqualified Users (Wrong state/country)"
          titleTag="h6"
          className="py-2"
          endEl={<CardDropdown />}
        />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              className: 'fs-10 mb-0 overflow-hidden',
            }}
          />
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  )
}

export default RecentLeads;