import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Card, Col, Row, Table, Form, ProgressBar } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Flex from 'components/common/Flex'
import SimpleBarReact from 'simplebar-react'

const BestSellingTableRow = ({ product, totalOrder }) => {
  const { voucherName, quantity, partnerName } = product

  return (
    <tr className="border-top border-200">
      <td>
        <Flex alignItems="center" className="position-relative">
          <div className="ms-3">
            <h6 className="mb-1 fw-semibold">
                {voucherName}
            </h6>
            <p className="fw-semibold mb-0 text-500">{partnerName}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semibold">{quantity}</td>
      <td className="align-middle text-center fw-semibold">{((quantity / totalOrder) * 100).toFixed(2)}%</td>
    </tr>
  )
}

const BestSellingProducts = () => {
  const [products, setProducts] = useState([])
  const [totalOrder, setTotalOrder] = useState(0)
  const [dateRange, setDateRange] = useState('7')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://ozz84ht568.execute-api.us-east-1.amazonaws.com/prod/transactions?days=${dateRange}`,
        )
        const data = response.data

        // Group by voucherName and sum quantities
        const groupedData = data.reduce((acc, item) => {
          const key = item.voucherName
          if (!acc[key]) {
            acc[key] = { ...item, quantity: 0 }
          }
          acc[key].quantity += item.quantity
          return acc
        }, {})

        const processedProducts = Object.values(groupedData)
        setProducts(processedProducts)
        setTotalOrder(processedProducts.reduce((sum, item) => sum + item.quantity, 0))
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [dateRange])

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Card className="h-lg-100 overflow-hidden">
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table borderless className="mb-0 fs-10">
            <thead className="bg-body-tertiary">
              <tr>
                <th className="text-900">Recent Voucher Sales</th>
                <th className="text-900 text-center">Orders({totalOrder})</th>
                <th className="text-900 text-center">Order(%)</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <BestSellingTableRow product={product} totalOrder={totalOrder} key={index} />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-body-tertiary py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" value={dateRange} onChange={handleDateRangeChange}>
              <option value="7">Last 7 days</option>
              <option value="30">Last Month</option>
              <option value="365">Last Year</option>
            </Form.Select>
          </Col>
          {/* <Col xs="auto">
            <Button variant="falcon-default" size="sm" as={Link} to="#!">
              View All
            </Button>
          </Col> */}
        </Row>
      </Card.Footer>
    </Card>
  )
}

BestSellingTableRow.propTypes = {
  totalOrder: PropTypes.number.isRequired,
  product: PropTypes.shape({
    voucherName: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
}

export default BestSellingProducts
