import React from 'react'
import { Row, Col } from 'react-bootstrap'

import StockList from './stock-month/StockList'
import {
  stock,
  purchasedStock,
  getStockByMonthAndYear,
  getSalesByMonthAndYear,
  getCurrentStock,
  getUnlabellededStock,
} from '../../../inventory/utils'

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(9, 2023)} title="Sep 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(10, 2023)} title="Oct 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(11, 2023)} title="Nov 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(12, 2023)} title="Dec 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(1, 2024)} title="Jan 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(2, 2024)} title="Feb 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(3, 2024)} title="Mar 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(4, 2024)} title="Apr 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(5, 2024)} title="May 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(6, 2024)} title="Jun 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <StockList data={getStockByMonthAndYear(7, 2024)} title="Jul 2024" />
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
