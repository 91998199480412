import React from 'react';
import TransactionsDetailsPage from './TransactionsDetailsPage';

const TransactionsDetails = () => {
  return (
    <>
      <TransactionsDetailsPage />
      {/* <CustomerLog /> */}
    </>
  );
};

export default TransactionsDetails;