import React, { useState, useEffect } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import CardDropdown from 'components/common/CardDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import SubtleBadge from 'components/common/SubtleBadge'
import { Link } from 'react-router-dom'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import RecentPurchasesHeader from './RecentPurchasesHeader'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import axios from 'axios'

const columns = [
  {
    accessor: 'customer',
    Header: 'Customer',
    headerProps: { className: 'pe-1 sticky-column text-900' },
    cellProps: {
      className: 'fw-semibold',
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: { className: 'pe-7 text-900' },
  },
  {
    accessor: 'product',
    Header: 'Voucher',
    headerProps: { className: 'text-900' },
    cellProps: {
      className: 'fw-semibold',
    },
    Cell: ({ row }) => {
      return <Link to={`/e-commerce/transaction-details/${row.original.transactionId}`}>{row.original.product}</Link>
    },
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { className: 'text-center text-900' },
    cellProps: {
      className: 'fs-9',
    },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      let bg, icon;

      switch (status) {
        case 'REDEEMED':
          bg = 'success';
          icon = faCheck;
          break;
        case 'DISPATCHED':
          bg = 'warning';
          icon = faClock;
          break;
        case 'ERROR':
          bg = 'danger';
          icon = faTimes;
          break;
        default:
          bg = 'secondary';
          icon = faClock;
      }

      return (
        <SubtleBadge pill bg={bg} className="d-block">
          {status}
          <FontAwesomeIcon icon={icon} transform="shrink-2" className="ms-1" />
        </SubtleBadge>
      )
    },
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    headerProps: {
      className: 'text-end text-900',
    },
    cellProps: {
      className: 'text-end',
    },
    Cell: (rowData) => {
      const amount = rowData.row.original.amount
      return amount != null ? `$${Number(amount).toFixed(2)}` : 'N/A'
    },
  },
]

const RecentPurchases = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ozz84ht568.execute-api.us-east-1.amazonaws.com/prod/transactions')
        const formattedData = response.data.map((item) => ({
          customer:
            item.buyerName || item.buyerSurname ? `${item.buyerName || ''} ${item.buyerSurname || ''}`.trim() : '-',
          email: item.buyerEmail || '-',
          product: item.voucherName || 'N/A',
          status: item.status || 'Unknown',
          amount: item.cartItem?.deal?.usdAmount != null ? Number(item.cartItem.deal.usdAmount) : null,
          transactionId: item.transactionId,
        }))
        setData(formattedData)
        setLoading(false)
      } catch (err) {
        setError('Failed to fetch data')
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <AdvanceTableWrapper columns={columns} data={data} selection sortable pagination perPage={7} rowCount={data.length}>
      <Card>
        <Card.Header>
          <RecentPurchasesHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs-10 mb-0 overflow-hidden',
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data.length} table rowInfo navButtons />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  )
}

export default RecentPurchases