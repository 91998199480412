import React from 'react';
import CustomerDetailsPage from './CustomerDetailsPage';
import CustomerLog from './CustomerLog';

const CustomersDetails = () => {
  return (
    <>
      <CustomerDetailsPage />
      {/* <CustomerLog /> */}
    </>
  );
};

export default CustomersDetails;