import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const TransactionDetailsPage = () => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { transactionId } = useParams();

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': 'p5ovMzoPPn7cDuZ94oeDa4IKJnry6vtJ93VL9lP2',
        };
        
        const url = `https://ozz84ht568.execute-api.us-east-1.amazonaws.com/prod/transactions?transactionId=${transactionId}`;
        const response = await fetch(url, { method: 'GET', headers });
        
        if (!response.ok) {
          throw new Error('Transaction details not available. Please try again later.');
        }
        
        const data = await response.json();
        setTransaction(data[0]);
        setLoading(false);
      } catch (error) {
        setError('Error fetching transaction details: ' + error.message);
        setLoading(false);
      }
    };
    
    fetchTransactionDetails();
  }, [transactionId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!transaction) return <div>No transaction found</div>;

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Transaction Details</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <h6 className="mb-3">Buyer Information</h6>
            <Flex>
              <FontAwesomeIcon icon="user" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Name:</strong> {transaction.buyerName} {transaction.buyerSurname}</p>
                <p className="mb-0"><strong>Email:</strong> {transaction.buyerEmail}</p>
                <p className="mb-0"><strong>Country:</strong> {transaction.buyerCountry}</p>
                <p className="mb-0"><strong>City:</strong> {transaction.buyerCity}</p>
                <p className="mb-0"><strong>Province:</strong> {transaction.buyerProvince}</p>
              </div>
            </Flex>
          </Col>
          <Col md={6}>
            <h6 className="mb-3">Voucher Information</h6>
            <Flex>
              <FontAwesomeIcon icon="ticket-alt" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Voucher Name:</strong> {transaction.voucherName}</p>
                <p className="mb-0"><strong>Status:</strong> {transaction.status}</p>
                <p className="mb-0"><strong>Quantity:</strong> {transaction.quantity}</p>
                <p className="mb-0"><strong>Date Purchased:</strong> {new Date(transaction.datePurchased).toLocaleString()}</p>
                <p className="mb-0"><strong>Amount:</strong> ${transaction.cartItem.deal.usdAmount}</p>
              </div>
            </Flex>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h6 className="mb-3">Recipient Information</h6>
            <Flex>
              <FontAwesomeIcon icon="user-tag" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Name:</strong> {transaction.cartItem.productRecipient.name} {transaction.cartItem.productRecipient.surname}</p>
                <p className="mb-0"><strong>Country:</strong> {transaction.recipientCountry}</p>
                <p className="mb-0"><strong>Mobile:</strong> {transaction.cartItem.productRecipient.mobileNumber}</p>
              </div>
            </Flex>
          </Col>
          <Col md={6}>
            <h6 className="mb-3">Partner Information</h6>
            <Flex>
              <FontAwesomeIcon icon="handshake" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Partner Name:</strong> {transaction.partnerName}</p>
                <p className="mb-0"><strong>Partner ID:</strong> {transaction.partnerId}</p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">Transaction ID: {transaction.transactionId}</small>
      </Card.Footer>
    </Card>
  );
};

export default TransactionDetailsPage;