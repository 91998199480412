import React from 'react'
import { Card } from 'react-bootstrap'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import StockListHeader from './StockListHeader'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'

const columns = [
  {
    accessor: 'id',
    Header: 'Stock ID',
    headerProps: { className: 'pe-1 sticky-column text-900' },
  },
  {
    accessor: 'date_stocked',
    Header: 'Date Stocked',
    headerProps: { className: 'pe-7 text-900' },
  },
  {
    accessor: 'product_name',
    Header: 'Product',
    headerProps: { className: 'text-center text-900' },
  },
  {
    accessor: 'redemption_currency',
    Header: 'Currency',
    headerProps: { className: 'text-end text-900' },
  },
  {
    accessor: 'redemption_value',
    Header: 'Value',
    headerProps: {
      className: 'text-end text-900',
    },
    cellProps: {
      className: 'text-end',
    },
  },
]

const StockList = ({ title, data }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      selection
      sortable
      pagination
      perPage={22}
      rowCount={data.length}
    >
      <Card>
        <Card.Header>
          <StockListHeader table title={title} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs-10 mb-0 overflow-hidden',
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={data.length} table rowInfo navButtons />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  )
}

export default StockList
