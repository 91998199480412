import dayjs from 'dayjs'
import stockData from './stock28072024.json'
import dealsData from './deals28072024.json'
import 'dayjs/locale/es'

function parseJSON(data) {
  try {
    return JSON.parse(JSON.stringify(data))
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return null
  }
}

const rawStock = parseJSON(stockData)
const deals = parseJSON(dealsData)
const stock = []
const purchasedStock = []

rawStock.forEach((stockItem) => {
  let matchingStock = { ...stockItem }
  const matchingDeal = deals.find((item) => item.product_id === stockItem.product_id)

  if (matchingDeal) {
    matchingStock.product_name = matchingDeal.product_name
    matchingStock.merchant_name = matchingDeal.merchant_name

    if (matchingDeal.zar) {
      matchingStock.redemption_value = matchingDeal.zar
      matchingStock.redemption_currency = 'ZAR'
    } else if (matchingDeal.country === 'US') {
      matchingStock.redemption_value = matchingDeal.redemption_amount
      matchingStock.redemption_currency = matchingDeal.redemption_currency
    }

    stock.push(matchingStock)
    if (matchingStock.status === 'dispatched') {
      purchasedStock.push(matchingStock)
    }
  } else {
    console.log('No matching deal found for product ID:', matchingStock.product_id)
  }
})

function getStockByMonthAndYear(month, year) {
  return stock.filter((item) => {
    const dateStocked = dayjs(item.date_stocked)
    return dateStocked.month() + 1 === month && dateStocked.year() === year
  })
}

function getSalesByMonthAndYear(month, year) {
  return stock.filter((item) => {
    const datePurchased = dayjs(item.date_purchased)
    return datePurchased.month() + 1 === month && datePurchased.year() === year && item.status === 'dispatched'
  })
}

function getCurrentStock() {
  const expiredStock = stock.filter((item) => {
    const isUsed = item.status === 'expired'
    return isUsed
  })
  console.log('expiredStock', expiredStock.length)
  console.log('expiredStock', expiredStock)

  const currentStock = stock.filter((item) => {
    const isAvailable = item.status === 'available'
    return isAvailable
  })
  console.log('currentStock', currentStock.length)
  return currentStock
}

function getUnlabellededStock(month, year) {
  const currentStock = getCurrentStock()
  const sales = getSalesByMonthAndYear(month, year)
  const stock = getStockByMonthAndYear(month, year)

  return stock.filter((item) => {
    const isNotInCurrentStock = !currentStock.find((stockItem) => stockItem.product_id === item.product_id)
    const isNotInSales = !sales.find((saleItem) => saleItem.product_id === item.product_id)
    return isNotInCurrentStock && isNotInSales
  })
}

export {
  stock,
  deals,
  purchasedStock,
  getStockByMonthAndYear,
  getSalesByMonthAndYear,
  getCurrentStock,
  getUnlabellededStock,
}
