import React from 'react'
import { Row, Col } from 'react-bootstrap'
import GreetingCard from './greetings/GreetingCard';
import SalesList from './sales-month/SalesList'
import {
  notifications,
} from 'data/dashboard/ecom';

const Dashboard = () => {
  return (
    <>
        <Row className="g-3 mb-3">
        <Col xxl={6} xl={12}>
          <Row className="g-3">
            <Col xs={12}>
              <GreetingCard notifications={notifications} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xs={12}>
          <SalesList/>
        </Col>
      </Row>
      {/* <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(10, 2023)} title="Oct 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(11, 2023)} title="Nov 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(12, 2023)} title="Dec 2023" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(1, 2024)} title="Jan 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(2, 2024)} title="Feb 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(3, 2024)} title="Mar 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(4, 2024)} title="Apr 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(5, 2024)} title="May 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(6, 2024)} title="Jun 2024" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <SalesList data={getSalesByMonthAndYear(7, 2024)} title="Jul 2024" />
        </Col>
      </Row> */}
    </>
  )
}

export default Dashboard
