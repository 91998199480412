import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const GreetingCard = ({ notifications }) => {
  const [greeting, setGreeting] = useState('');
  const [userName, setUserName] = useState('Ramona');

  useEffect(() => {
    const updateGreeting = () => {
      const kenyaTime = new Date().toLocaleString("en-US", {timeZone: "Africa/Nairobi"});
      const kenyaHour = new Date(kenyaTime).getHours();

      let newGreeting;
      if (kenyaHour >= 5 && kenyaHour < 12) {
        newGreeting = 'Good Morning';
      } else if (kenyaHour >= 12 && kenyaHour < 18) {
        newGreeting = 'Good Afternoon';
      } else {
        newGreeting = 'Good Evening';
      }
      setGreeting(newGreeting);
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setUserName('Ramona');
  }, []);

  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Card.Header className="position-relative">
        <div className="position-relative z-index-2">
          <div>
            <h3 className="text-primary mb-1">{`${greeting}, ${userName}!`}</h3>
            <p className="text-700">
              Here's where you get details on users
            </p>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object)
};

export default GreetingCard;