import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useAppContext } from 'Main';

const CustomerDetailsPage = () => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const location = useLocation();

  const userType = location.state?.userType || 'verified';

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': 'p5ovMzoPPn7cDuZ94oeDa4IKJnry6vtJ93VL9lP2',
        };
        
        const url = `https://ozz84ht568.execute-api.us-east-1.amazonaws.com/prod/users?type=${userType}&id=${id}`;
        const response = await fetch(url, { method: 'GET', headers });
        
        if (!response.ok) {
          throw new Error('Customer details not available. Please try again later.');
        }
        
        const data = await response.json();
        
        if (Array.isArray(data) && data.length > 0) {
          setCustomer(data[0]); 
        } else {
          setCustomer(null);
          setError(`No ${userType} customer found with ID: ${id}`);
        }
        
        setLoading(false);
      } catch (error) {
        setError('Error fetching customer details: ' + error.message);
        setLoading(false);
      }
    };
    
    fetchCustomerDetails();
  }, [id, userType]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!customer) return <div>No customer found</div>;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="g-2">
          <Col>
            <h5 className="mb-2">
              {customer.name} {customer.surname} (<a href={`mailto:${customer.email}`}>{customer.email}</a>)
            </h5>
          </Col>
          <Col xs="auto">
            <span className="text-uppercase text-600">
              Customer <FontAwesomeIcon icon="user" className="ms-2" />
            </span>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="border-top">
        <Row>
          <Col md={6}>
            <h6 className="mb-3">Account Information</h6>
            <Flex>
              <FontAwesomeIcon icon="user" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Name:</strong> {customer.name} {customer.surname}</p>
                <p className="mb-0"><strong>Email:</strong> {customer.email}</p>
                <p className="mb-0"><strong>Phone:</strong> {customer.mobileNumber}</p>
                {customer.dateOfBirth && <p className="mb-0"><strong>Date of Birth:</strong> {customer.dateOfBirth}</p>}
                {customer.gender && <p className="mb-0"><strong>Gender:</strong> {customer.gender}</p>}
                {customer.referralCode && <p className="mb-0"><strong>Referral Code:</strong> {customer.referralCode}</p>}
              </div>
            </Flex>
          </Col>
          <Col md={6}>
            <h6 className="mb-3">Location Information</h6>
            <Flex>
              <FontAwesomeIcon icon="map-marker-alt" className="text-success me-2" transform="down-5" />
              <div className="flex-1">
                <p className="mb-0"><strong>Country:</strong> {customer.country}</p>
                {customer.city && <p className="mb-0"><strong>City:</strong> {customer.city}</p>}
                {customer.province && <p className="mb-0"><strong>Province:</strong> {customer.province}</p>}
                {customer.postCode && <p className="mb-0"><strong>Post Code:</strong> {customer.postCode}</p>}
                {customer.voucherCountries && (
                  <p className="mb-0"><strong>Voucher Countries:</strong> {customer.voucherCountries.join(', ')}</p>
                )}
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">Customer ID: {customer.id}</small>
        <br />
        <small className="text-muted">Created At: {new Date(customer.createdAt).toLocaleString()}</small>
      </Card.Footer>
    </Card>
  );
};

export default CustomerDetailsPage;