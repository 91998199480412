import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GreetingCard from './greetings/GreetingCard';
import BestSellingProducts from './BestSellingProducts';
import RecentPurchases from 'components/dashboards/transactions/recent-purchases/RecentPurchases';
import {
  notifications,
  products,
} from 'data/dashboard/ecom';

const Transactions = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={6} xl={12}>
          <Row className="g-3">
            <Col xs={12}>
              <GreetingCard notifications={notifications} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={12} xxl={9}>
          <RecentPurchases />
        </Col>
      </Row>
    </>
  );
};

export default Transactions;
