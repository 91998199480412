import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCTu2yP4mN--DoUsd5gNYwMtrIOruf_p-8',
  authDomain: 'lipa-web.firebaseapp.com',
  projectId: 'lipa-web',
  storageBucket: 'lipa-web.appspot.com',
  messagingSenderId: '576115288592',
  appId: '1:576115288592:web:d1118c0d6b3336964cb895',
  measurementId: 'G-454HM3S6RG',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app)

export { auth, analytics }
